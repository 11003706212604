import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/template/Layout"
import { CurrentView } from '../../entities/templates';
import { CountryPagePropsType, lang, Highlights } from '../../entities/queriesTypes'
import useSetGlobalState from '../../components/hooks/useSetGlobalState'
import Wrapper from '../../components/template/ContainerMax';
import { useGlobalStateContext } from '../../Context/site'
import translations from '../../entities/translations';
import PageSection from '../../components/organism/HomePageComponent';
import {Card} from '../../components/molecule/Card'
import { buildImageFocalPoint } from '../../utils';
import { countrySubMenu } from '../../utils/subNavigation'
import PortableTextRenderer from "../../components/sanityBlockContents/PortableTextRenderer";

const IndexPage:React.FC<CountryPagePropsType> = (props) => {
  const { data, pageContext } = props
  const page = data && data.pageData
  const locale = pageContext && pageContext.locale
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView.country ,
    subNavigationItem: countrySubMenu,
    subNavigationTitle: undefined
  })
  const { state } = useGlobalStateContext();
  const buttonTransaltion = translations.globals.buttons;
  const pageID = translations.globals.subMenuTranslation;
  const [countryHighlights, setCountryHighlights] = useState<Highlights [] | undefined>(undefined)
  const [showReadMore, setShowReadMore] = useState<boolean>(true)
  const _regionTranslation = translations.CountryPages.find((item) => item.menu === 'Orte')!;
  const pageHash = translations.countryMenuID;

  useEffect(() => {
    if (page?.countryHighlights?.length) {
      const _highlights = [...page?.countryHighlights]
      if (_highlights.length < 3) {
        setCountryHighlights(_highlights)
      } else {
        _highlights.length = 3
        setCountryHighlights(_highlights)
      }
    }
  }, [page?.countryHighlights])

  const handleReadMore = () => {
    setCountryHighlights(page?.countryHighlights)
    setShowReadMore(false)
  }

  return (
    <Layout seoTitle={(page?.metadata?.page_title && page?.metadata?.page_title[state?.lang as lang]) as string} meta={[
      { name: 'description', content: page?.metadata?.page_description ? page?.metadata?.page_description[state?.lang as lang] as string : '' },
      { name: 'keywords', content: page?.metadata?.page_keywords ? page?.metadata?.page_keywords[state?.lang as lang] as string : ''},
    ]}>
      <Wrapper className="pt-16" id={pageHash?.Hohepunkte?.[state?.lang as lang]!}>
        {page?.countryHighlightsIntroCopy?.[state?.lang as lang]
          && <PortableTextRenderer body={page?.countryHighlightsIntroCopy?.[state?.lang as lang]} />}

      </Wrapper>
      <PageSection
      onClick={handleReadMore}
      showReadMore={showReadMore}
      sanityContext={null}
      id={``}
      idTitle={pageID.Hohepunkte[state?.lang as lang]!}
      title={''}>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
          {countryHighlights?.map((item, i: number) => <Card
          uniqueRef={item?.['_id']!}
          key={i}
          _className="mx-2 sm:w-full md:w-full lg:w-full mb-4"
          imageAlt={(item?.regionImage?.alt && item?.regionImage?.alt[state?.lang as lang]) as string}
          imageUrl={buildImageFocalPoint(item.regionImage?.image)}
          title={(item?.title && item?.title[state?.lang as lang])|| ''}
          ctaUrl={`/${state?.lang}/${item?.parentCountry?.slug && 
            item?.parentCountry?.slug[state?.lang as lang]?.current}/${_regionTranslation.slug[state?.lang as lang]}/${item?.slug 
              && item?.slug[state?.lang as lang]?.current}#${pageID.Orte[state?.lang as lang]!}`}
          ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
          cardType="COUNTRY"
          cardSize={'SMALL'}
          /> )}
        </div>
      </PageSection>
    </Layout>
)}

export default IndexPage

export const query = graphql`
  query countryHighlightsPage($id: String!) {
    pageData: sanityCountry(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle(resolveReferences: {maxDepth: 10})
      heroImage: _rawCountryHighlightsImage(resolveReferences: {maxDepth: 2})
      countryHighlightsIntroCopy :_rawCountryHighlightsIntroCopy(resolveReferences: {maxDepth: 10})
      countryHighlights {
        _id
        regionImage {
          alt: _rawAlt
          image: _rawImage
        }
        title {
          de
        }
        slug {
          de {
            current
          }
        }
        parentCountry {
          slug {
            de {
              current
            }
          }
        }
      }
      metadata: _rawCountryHighlightsMetadata
    }
  }
`
